body {
  background: #f2dfa2;
  font-family: 'Roboto Condensed', sans-serif;
  color: #0a0909;
}

h1, h2 {
  font-family: 'Patua One', cursive;
}

strong {
  font-weight: 700;
}

.jumbotron {
  background: #eacb66;
  border-bottom: 3px solid white;
}

.navbar-dark {
  background: #232628;
}

.nav-item {
  margin: 0 20px;

}

.nav-item a {
  min-width: 220px;
  font-size: 20px;
  padding: 8px 18px;
  position: relative;
  background: white;
  color: black;
  font-weight: 700;
  margin: 0;
  -webkit-box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.75);
  box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.75);
}

.nav-item .active {
  background: #f8efcf;
}

.nav-item a:active {
  background: white;
  color: black;
  font-weight: 700;
  margin: 3px 0 -3px 3px;
  -webkit-box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.75);
  box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.75);
}

.footer-body {
  border-top: 3px solid white;
}

hr {
  height: 1px;
  border: 0;
  border-top: 3px solid white;
  margin: 1em 0;
  padding: 0;
}

a {
  color: #3574D3;
}

.pdf-thumb {
  border: 8px solid white;
  -webkit-box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.75);
  box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.75);
}
